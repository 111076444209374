<template>
  <a-form ref="form" :model="form.internal" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Internal') }}
      </h3>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Physical Key Custodian')"
      name="physicalKeyCustodian"
    >
      <a-input v-model:value="form.internal.physicalKeyCustodian" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Physical Key Custodian Company')"
      name="physicalKeyCustodianCompany"
    >
      <a-input v-model:value="form.internal.physicalKeyCustodianCompany" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Construction Category')"
      name="constructionCategory"
    >
      <a-select
        v-model:value="form.internal.constructionCategory"
        :size="size"
        option-filter-prop="key"
      >
        <a-select-option v-for="type in constructionType" :key="type.value" :value="type.value">
          {{ $t(type.value) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Operation Status')" name="operationStatus">
      <a-select v-model:value="form.internal.operationStatus" :size="size" option-filter-prop="key">
        <a-select-option
          v-for="status in operationalStatus"
          :key="status.value"
          :value="status.value"
        >
          {{ $t(status.value) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Contract Status')" name="contractStatus">
      <a-select v-model:value="form.internal.contractStatus" :size="size" option-filter-prop="key">
        <a-select-option v-for="status in contractStatus" :key="status.value" :value="status.value">
          {{ $t(status.value) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Reservation Start Date')"
      name="reservationStartDate"
    >
      <a-date-picker
        v-model:value="form.internal.reservationStartDate"
        :size="size"
        :format="dateFormat"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Contract End Date')" name="contractEndDate">
      <a-date-picker
        v-model:value="form.internal.contractEndDate"
        :size="size"
        :format="dateFormat"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Contract Type')" name="contractType">
      <a-select v-model:value="form.internal.contractType" :size="size" option-filter-prop="key">
        <a-select-option v-for="type in contractType" :key="type.value" :value="type.value">
          {{ $t(type.value) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Sales PIC')" name="salesPic">
      <a-input v-model:value="form.internal.salesPic" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Opening PIC')" name="openingPic">
      <a-input v-model:value="form.internal.openingPic" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Property Manager')"
      name="propertyManagerId"
    >
      <InputUser
        v-model:value="form.internal.propertyManagerId"
        role="OPS"
        :size="size"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Revenue Manager')"
      name="revenueManager"
    >
      <InputUser
        v-model:value="form.internal.revenueManagerId"
        role="SALES"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Design Company')" name="designCompany">
      <a-input v-model:value="form.internal.designCompany" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Construction Company')"
      name="constructionCompany"
    >
      <a-input v-model:value="form.internal.constructionCompany" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Image URL')" name="imageUrl">
      <a-input v-model:value="form.internal.imageUrl" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Channel Manager')" name="channelManager">
      <a-input v-model:value="form.internal.channelManager" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Cleaning Fee')" name="cleaningFee">
      <a-input-number
        v-model:value="form.internal.cleaningFee"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Emergency Assistant Fee')"
      name="emergencyAssistantFee"
    >
      <a-input-number
        v-model:value="form.internal.emergencyAssistantFee"
        style="width: 100%;"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Currency')"
      name="currency"
    >
      <InputCurrency
        v-model:value-model="form.internal.currency"
        :size="size"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Timezone')"
      name="timezone"
    >
      <InputTimezone
        v-model:value-model="form.timezone"
        :size="size"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Internal": "Edit Internal",
    "Physical Key Custodian": "Physical Key Custodian",
    "Physical Key Custodian Company": "Physical Key Custodian Company",
    "Construction Category": "Construction Category",
    "Operation Status": "Operation Status",
    "Contract Status": "Contract Status",
    "Reservation Start Date": "Reservation Start Date",
    "Contract End Date": "Contract End Date",
    "Contract Type": "Contract Type",
    "Sales PIC": "Sales PIC",
    "Opening PIC": "Opening PIC",
    "Property Manager": "Property Manager",
    "Revenue Manager": "Revenue Manager",
    "Design Company": "Design Company",
    "Construction Company": "Construction Company",
    "Image URL": "Image URL",
    "Channel Manager": "Channel Manager",
    "Cleaning Fee": "Cleaning Fee",
    "Emergency Assistant Fee": "Emergency Assistant Fee",
    "Currency": "Currency",
    "Cleaning fee is required": "Cleaning fee is required",
    "Emergency assistant fee is required": "Emergency assistant fee is required",
    "Currency is required": "Currency is required",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja": {
    "Edit Internal": "その他運用情報を編集",
    "Physical Key Custodian": "物理キー保有者",
    "Physical Key Custodian Company": "物理鍵保管主体",
    "Construction Category": "築区分",
    "Operation Status": "稼働状況",
    "Contract Status": "契約状態",
    "Reservation Start Date": "予約受付開始日",
    "Contract End Date": "契約完了日時",
    "Contract Type": "契約タイプ",
    "Sales PIC": "事業創造担当者",
    "Opening PIC": "開発担当者",
    "Property Manager": "運用担当者",
    "Revenue Manager": "販売推進担当者",
    "Design Company": "設計会社",
    "Construction Company": "施工会社",
    "Image URL": "写真URL",
    "Channel Manager": "チャネルマネージャー",
    "Cleaning Fee": "清掃費",
    "Emergency Assistant Fee": "立替金精算",
    "Currency": "通貨",
    "Cleaning fee is required": "清掃費は必須です",
    "Emergency assistant fee is required": "立替金精算は必須です",
    "Currency is required": "通貨は必須です",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
import InputCurrency from '@/components/InputCurrency';
import InputUser from '@/components/InputUser';
import {
  OPERATIONAL_STATUS,
  CONTRACT_STATUS,
  CONTRACT_TYPE,
  CONSTRUCTION_TYPE,
} from '@/config/properties';
import InputTimezone from '@/components/InputTimezone';

export default {
  name: 'EditPropertyInternalForm',
  components: {
    InputTimezone,
    InputCurrency,
    InputUser,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      operationalStatus: OPERATIONAL_STATUS,
      contractStatus: CONTRACT_STATUS,
      contractType: CONTRACT_TYPE,
      constructionType: CONSTRUCTION_TYPE,
      form: {
        internal: {
          physicalKeyCustodian: '',
          physicalKeyCustodianCompany: '',
          constructionCategory: '',
          operationStatus: '',
          contractStatus: '',
          reservationStartDate: '',
          contractEndDate: '',
          contractType: '',
          salesPic: '',
          openingPic: '',
          propertyManagerId: '',
          revenueManagerId: '',
          designCompany: '',
          constructionCompany: '',
          imageUrl: '',
          channelManager: '',
          cleaningFee: '',
          emergencyAssistantFee: '',
          currency: '',
        },
        timezone: '',
      },
      rules: {
        cleaningFee: [
          {
            required: true,
            type: 'number',
            message: this.$t('Cleaning fee is required'),
            trigger: ['change', 'blur'],
          },
        ],
        emergencyAssistantFee: [
          {
            required: true,
            type: 'number',
            message: this.$t('Emergency assistant fee is required'),
            trigger: ['change', 'blur'],
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('Currency is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const values = JSON.parse(JSON.stringify(nv));
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = values[k];
        });

        this.form.internal.propertyManagerId = nv.propertyManagerId;
        this.form.internal.revenueManagerId = nv.revenueManagerId;
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const { propertyManagerId, revenueManagerId, ...internal } = this.form.internal;
        const attributes = {
          id: this.value.newPropertyCode,
          propertyManagerId,
          revenueManagerId,
          internal,
          timezone: this.form.timezone,
        };

        await this.$store.dispatch('properties/update', attributes);

        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
