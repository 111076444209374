<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Room') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <RoomForm
          v-if="property"
          ref="form"
          :facility-list="facilityList"
          :is-submitting="isSubmitting"
          :property="property"
          :anshin-rooms="anshinRooms"
          @submit="handleSubmit"
        />
        <a-alert v-if="room" type="success">
          <template #message>
            <span v-html="$t('room-added', { name: room.name })" />
            <router-link :to="{ name: 'property-rooms' }">
              {{ $t('View rooms page') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add New Room": "Add New Room",
"room-added": "room <strong>{name}</strong> added,",
"View rooms page": "View rooms page"
},
"ja": {
"Add New Room": "部屋追加",
"room-added": "部屋 {name} が追加されました。",
"View rooms page": "部屋を閲覧"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import RoomForm from '@/views/properties/components/RoomForm';

export default {
  name: 'RoomAdd',
  components: { RoomForm },
  data() {
    return {
      room: undefined,
      isSubmitting: false,
      error: '',
      facilityList: [],
      neppanRoomTypes: [],
      anshinRooms: [],
    };
  },
  computed: {
    ...mapState('properties', ['property']),
  },
  async created() {
    await this.$store.dispatch('properties/get', { id: this.$route.params.id });
    this.getFacilityList();
    // if (this.property.neppanPropertyCode) {
    //   this.getNeppanRoomTypes();
    // }
    if (this.property.anshinPropertyId) {
      this.getAnshinAccommodations();
    }
  },
  methods: {
    getFacilityList() {
      this.$store.dispatch('facilities/list').then((res) => {
        this.facilityList = res.list;
      });
    },
    getAnshinAccommodations() {
      this.$store
        .dispatch('rooms/listAnshinAccommodations', {
          accommodationsGroupId: this.property.anshinPropertyId,
          anshinAccountId: this.property.anshinAccountId,
          availableOnly: true,
        })
        .then((resp) => {
          this.anshinRooms = resp.list;
        });
    },
    getNeppanRoomTypes() {
      this.$store
        .dispatch('rooms/listNeppanRoomTypes', { propertyId: this.property.newPropertyCode })
        .then((res) => {
          this.neppanRoomTypes = res.rooms.map((room) => room.roomTypeGroupCode);
        });
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      this.room = undefined;
      this.error = '';
      // if (this.property.neppanPropertyCode && this.neppanRoomTypes.indexOf(data.code) < 0) {
      //   this.error = 'Invalid room code, make sure room code exist on neppan';
      //   return;
      // }
      this.$store
        .dispatch('rooms/add', { propertyId: this.$route.params.id, ...data })
        .then(() => {
          this.isSubmitting = false;
          this.room = data;
          this.$refs.form.resetFields();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.error = err.response.data.errors.message;
          }
          this.isSubmitting = false;
        });
    },
  },
};
</script>
