<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Building') }}
      </h3>
    </div>
    <a-form-item
      class="column is-6"
      :label="$t('Total Floor Area (square meters)')"
      name="building.totalFloorArea"
    >
      <a-input v-model:value="form.building.totalFloorArea" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Number of Rooms')" name="building.numberOfRooms">
      <a-input-number
        v-model:value="form.building.numberOfRooms"
        style="width: 100%"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Overall Layout')" name="building.overallLayout">
      <a-upload
        v-model:file-list="fileList"
        name="file"
        list-type="picture-card"
        accept="image/png,image/jpeg"
        :multiple="false"
        :before-upload="handleBeforeUpload"
        :remove="handleRemoveLayout"
        :action="uploadLayoutAction"
        :headers="apiHeaders"
        :show-upload-list="{ showRemoveIcon: false }"
        @change="handleLayoutChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Nearby Landmarks')"
      name="building.nearbyLandmarks"
    >
      <a-input v-model:value="form.building.nearbyLandmarks" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Access from Nearby Station')"
      name="building.accessFromNearbyStation"
    >
      <a-input v-model:value="form.building.accessFromNearbyStation" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-6"
      :label="$t('Number of Car Park Slots')"
      name="building.numberOfCarParkSlots"
    >
      <a-input v-model:value="form.building.numberOfCarParkSlots" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-6"
      :label="$t('Car Park Location')"
      name="building.carParkLocation"
    >
      <a-input v-model:value="form.building.carParkLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Car Park Rules')" name="building.carParkRules">
      <a-input v-model:value="form.building.carParkRules" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Car Park Location Reference')"
      name="building.carParkLocationReference"
    >
      <a-input v-model:value="form.building.carParkLocationReference" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Places where guests are not allowed to use')"
      name="building.placesNotAllowedToUse"
    >
      <a-input v-model:value="form.building.placesNotAllowedToUse" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Notes')" name="building.notes">
      <a-input v-model:value="form.building.notes" :size="size" type="text" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en":{
    "Edit Building": "Edit Building",
    "Total Floor Area (square meters)": "Total Floor Area (square meters)",
    "Number of Rooms": "Number of Rooms",
    "Overall Layout": "Overall Layout",
    "Browse File": "Browse File",
    "Nearby Landmarks": "Nearby Landmarks",
    "Nearby Station": "Nearby Station",
    "Access from Nearby Station": "Access from Nearby Station",
    "Number of Car Park Slots": "Number of Car Park Slots",
    "Car Park Location": "Car Park Location",
    "Car Park Rules": "Car Park Rules",
    "Car Park Location Reference": "Car Park Location Reference",
    "Places where guests are not allowed to use": "Places where guests are not allowed to use",
    "Notes":"Notes",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja":{
    "Edit Building": "建物編集",
    "Total Floor Area (square meters)": "延床面積 (平米数)",
    "Number of Rooms": "部屋数",
    "Overall Layout": "全体配置図",
    "Browse File": "閲覧ファイル",
    "Nearby Landmarks": "近くの目印",
    "Access from Nearby Station": "最寄り駅・Access",
    "Number of Car Park Slots": "駐車可能台数",
    "Car Park Location": "駐車場位置情報",
    "Car Park Rules": "駐車場ルール",
    "Car Park Location Reference": "駐車場位置閲覧",
    "Places where guests are not allowed to use": "ゲスト使用不可能場所",
    "Notes":"注意事項",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
import { getToken } from '@/utils/auth';
import { uploadableImage } from '@/utils/util';

export default {
  name: 'EditPropertyBuildingForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        building: {
          totalFloorArea: undefined,
          numberOfRooms: undefined,
          overallLayout: undefined,
          nearbyLandmarks: undefined,
          nearbyStation: undefined,
          accessFromNearbyStation: undefined,
          numberOfCarParkSlots: undefined,
          carParkLocation: undefined,
          carParkRules: undefined,
          carParkLocationReference: undefined,
          placesNotAllowedToUse: undefined,
          notes: undefined,
        },
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
      apiHeaders: {
        authorization: `Bearer ${getToken()}`,
      },
      fileList: [],
      overallLayout: undefined,
    };
  },
  computed: {
    uploadLayoutAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/properties/${this.value.id}/layout`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const values = JSON.parse(JSON.stringify(nv));
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = values[k];
        });

        if (nv && nv.building.overallLayout && typeof nv.building.overallLayout === 'string') {
          this.fileList = [uploadableImage({ path: nv.building.overallLayout })];
        }
      },
    },
  },
  methods: {
    handleRemoveLayout(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.overallLayout = '';

      return false;
    },
    handleBeforeUpload(file) {
      this.fileList = [file];
      this.overallLayout = file;

      return false;
    },
    handleLayoutChange(info) {
      const fileList = [...info.fileList];
      this.fileList = fileList.slice(-1);
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const id = this.value.newPropertyCode;

        await this.$store.dispatch('properties/update', { id, ...this.form });

        if (this.overallLayout && typeof this.overallLayout !== 'string') {
          const layoutPayload = { id, file: this.overallLayout };

          await this.$store.dispatch('properties/uploadLayout', layoutPayload);
        }

        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
