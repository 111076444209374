<template>
  <Delete
    :action-handler="action"
    :payload-value="payloadValue"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en": {
"title": "Delete facility?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?",
"deleted-title": "Facility is deleted",
"deleted-desc": "Facility <strong>{name}</strong> has been removed."
},
"ja": {
"title": "Delete facility?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?",
"deleted-title": "Facility is deleted",
"deleted-desc": "Facility <strong>{name}</strong> has been removed."
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteFacility',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    action: {
      type: String,
      default: 'facilities/delete',
    },
    payload: {
      type: String || Object,
      default: null,
    },
  },
  emits: ['delete'],
  computed: {
    payloadValue() {
      if (this.payload) {
        return this.payload;
      }

      return { id: this.record.id };
    },
  },
};
</script>
