<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('title') }}
      </h3>
    </div>

    <a-form-item class="column is-12">
      <a-checkbox
        v-model:checked="priceAutoReflect"
        @change="(e) => togglePriceAutoReflect(e.target.checked)"
      >
        {{ $t('Price auto reflection flag') }}
      </a-checkbox>
    </a-form-item>
    <a-form-item
      class="column is-12"
      name="priceAutoReflection"
    >
      <a-radio-group
        v-model:value="form.priceAutoReflection"
        :disabled="!priceAutoReflect"
      >
        <a-radio value="room">
          {{ $t('Per room') }}
        </a-radio>
      </a-radio-group>
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "title": "Edit preferences",
    "Per person": "Per person flag",
    "Per room": "Per room flag"
  },
  "ja": {
    "title": "Edit preferences"
  }
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';

export default {
  name: 'EditPropertyPreferencesForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      priceAutoReflect: false,
      form: {
        priceAutoReflection: undefined,
      },
      size: 'large',
      loading: false,
    };
  },
  computed: {
    rules() {
      const rules = {};

      if (this.priceAutoReflect) {
        rules.priceAutoReflection = [
          {
            required: true,
            message: this.$t('Please select a price auto reflection flag'),
            trigger: ['change', 'blur'],
          },
        ];
      }

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });

          this.priceAutoReflect = !!this.form.priceAutoReflection;
        }
      },
    },
  },
  methods: {
    togglePriceAutoReflect(checked) {
      this.priceAutoReflect = checked;

      if (checked === false) {
        this.form.priceAutoReflection = null;
      }
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        await this.$store.dispatch('properties/update', {
          id: this.value.newPropertyCode,
          ...this.form,
        });
        this.$refs.form.resetFields();

        this.$emit('close');
        this.loading = false;
      } catch (e) {
        const error = getResponseError(e);

        if (error) {
          this.$message.error(error);
        }
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
