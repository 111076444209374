<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Add Incident') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Incident Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Description')" name="description">
      <a-input v-model:value="form.description" :size="size" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Upload pictures')" name="images">
      <a-upload
        name="file"
        list-type="picture-card"
        :file-list="fileList"
        :action="addImagesAction"
        :multiple="true"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Status')" name="status">
      <a-select v-model:value="form.status" :size="size">
        <a-select-option v-for="o in statusOptions" :key="o.label" :value="o.value">
          {{ o.label }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Insurance Status')" name="insuranceStatus">
      <a-select v-model:value="form.insuranceStatus" :size="size">
        <a-select-option v-for="o in insuranceStatusOptions" :key="o.label" :value="o.value">
          {{ o.label }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en":{
"Add Incident": "Add Incident",
"Incident Name": "Incident Name",
"Description": "Description",
"Upload pictures": "Upload pictures",
"Browse File": "Browse File",
"Status": "Status",
"Insurance Status": "Insurance Status",
"Cancel": "Cancel",
"Save": "Save",
"Fixed": "Fixed",
"Not fixed": "Not fixed",
"Covered": "Covered",
"Not covered": "Not covered",
"Not requested": "Not requested",
"name-required": "Name is required",
"status-required": "Status is required",
"insurance-required": "Insurance status is required"
},
"ja":{
"Add Incident": "重大な設備不備を追加",
"Incident Name": "重大な設備不備名",
"Description": "詳細",
"Upload pictures": "写真アップロード",
"Browse File": "閲覧ファイル",
"Status": "ステータス",
"Insurance Status": "保険ステータス",
"Cancel": "取り消し",
"Save": "保存",
"Fixed": "解決済",
"Not fixed": "未解決",
"Covered": "適応済",
"Not covered": "未適応",
"Not requested": "リクエストされていない",
"name-required": "重大な設備不備名は必須です",
"status-required": "ステータスは必須です",
"insurance-required": "保険ステータスは必須です"
}
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'AddIncidentForm',
  mixins: [FileUploader],
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      incident: undefined,
      form: {
        name: undefined,
        description: undefined,
        propertyId: undefined,
        status: undefined,
        insuranceStatus: undefined,
        images: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('name-required'),
            trigger: ['change', 'blur'],
          },
        ],
        status: [
          {
            required: true,
            message: this.$t('status-required'),
            trigger: ['change', 'blur'],
          },
        ],
        insuranceStatus: [
          {
            required: true,
            message: this.$t('insurance-required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      loading: false,
      fileList: [],
      statusOptions: [
        { label: this.$t('Fixed'), value: 'fixed' },
        { label: this.$t('Not fixed'), value: 'not fixed' },
      ],
      insuranceStatusOptions: [
        { label: this.$t('Covered'), value: 'covered' },
        { label: this.$t('Not covered'), value: 'not covered' },
        { label: this.$t('Not requested'), value: 'not requested' },
      ],
    };
  },
  computed: {
    addImagesAction() {
      if (!this.incident) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/incidents/${this.incident.id}/images`;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.incident = undefined;
        this.loading = true;

        const { images, ...formData } = this.form;
        const payload = { ...formData, propertyId: this.property.newPropertyCode };

        const { data } = await this.$store.dispatch('incidents/add', payload);

        if (data && images.length) {
          const imgPayload = {
            id: data.id,
            file: images,
          };

          await this.$store.dispatch('incidents/addImages', imgPayload);
        }

        this.incident = data;
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$message.error(getResponseError(e) || 'Error');
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.removeFromImages(file);

      // prevent default removal
      return false;
    },
  },
};
</script>

<style scoped></style>
