<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit property info') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Anshin Account')" name="anshinAccount">
      <a-select
        v-model:value="form.anshinAccountId"
        :size="size"
        @change="handleAnshinAccountChange"
      >
        <a-select-option v-for="item in anshinAccounts" :key="item.id" :value="item.id">
          {{ item.domain }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" label="Anshin Property" name="anshinProperty">
      <a-select
        v-model:value="form.anshinPropertyId"
        :size="size"
        :loading="isFetchingAnshin"
        @change="handleAnshinPropertyChange"
      >
        <a-select-option
          v-for="item in anshinProperties"
          :key="item.accommodationsGroupId"
          :value="item.accommodationsGroupId"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('New Property Code')" name="newPropertyCode">
      <a-input v-model:value.trim="form.newPropertyCode" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" label="Neppan Property Code" name="neppanPropertyCode">
      <a-select
        v-model:value="form.neppanPropertyCode"
        :size="size"
        @change="handleNeppanPropertyChange"
      >
        <a-select-option v-for="item in neppanAccounts" :key="item.code" :value="item.code">
          {{ item.code }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Abbreviation')" name="abbreviation">
      <a-input v-model:value="form.abbreviation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name (English)')" name="nameEn">
      <a-input v-model:value="form.nameEn" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name (Japanese)')" name="nameKana">
      <a-input v-model:value="form.nameKana" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Main Brand')" name="mainBrand">
      <a-input v-model:value="form.mainBrand" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Sub Brand')" name="subBrand">
      <a-input v-model:value="form.subBrand" :size="size" type="text" />
    </a-form-item>

    <a-form-item class="column is-6" :label="$t('Property Size')" name="size">
      <a-select v-model:value="form.size" :size="size">
        <a-select-option value="small">
          {{ $t('Small') }}
        </a-select-option>
        <a-select-option value="large">
          {{ $t('Large') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-6" :label="$t('Tablet')" name="tablet.tablet">
      <a-select v-model:value="form.tablet.tablet" :size="size">
        <a-select-option value="Yes">
          {{ $t('Yes') }}
        </a-select-option>
        <a-select-option value="No">
          {{ $t('No') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-6" :label="$t('Tablet Mode')" name="tablet.mode">
      <a-select v-model:value="form.tablet.mode" :size="size">
        <a-select-option value="Lobby">
          {{ $t('Lobby') }}
        </a-select-option>
        <a-select-option value="Room">
          {{ $t('Room') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Tablet Location')" name="tablet.location">
      <a-input v-model:value="form.tablet.location" :size="size" type="text" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit property info": "Edit property info",
"New Property Code": "New Property Code",
"Property Abbreviation": "Property Abbreviation",
"Property Name": "Property Name",
"Property Name (English)": "Property Name (English)",
"Property Name (Japanese)": "Property Name (Japanese)",
"Main Brand": "Main Brand",
"Sub Brand": "Sub Brand",
"Property Size": "Property Size",
"Small": "Small",
"Large": "Large",
"Property Size": "Property Size",
"Small": "Small",
"Large": "Large",
"Tablet": "Tablet",
"Yes": "Yes",
"No": "No",
"Tablet Mode": "Tablet Mode",
"Lobby": "Lobby",
"Room": "Room",
"Tablet Location": "Tablet Location",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Edit property info": "物件情報",
"New Property Code": "新規物件コード",
"Property Abbreviation": "物件略称",
"Property Name": "物件正式名称",
"Property Name (English)": "物件正式名称（英語）",
"Property Name (Japanese)": "物件正式名称（日本語）",
"Main Brand": "メインブランド",
"Sub Brand": "サブブランド",
"Property Size": "プロパティサイズ",
"Small": "小さな",
"Large": "大きい",
"Property Size": "プロパティサイズ",
"Small": "小さな",
"Large": "大きい",
"Tablet": "タブレット",
"Yes": "はい",
"No": "なし",
"Tablet Mode": "タブレットモード",
"Lobby": "ロビー",
"Room": "部屋",
"Tablet Location": "タブレット場所",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
export default {
  name: 'EditPropertyInfoForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    anshinAccounts: {
      type: Array,
      required: true,
      default: () => [],
    },
    neppanAccounts: {
      type: Array,
      required: true,
      default: () => [],
    },
    anshinProperties: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['close', 'cancel', 'anshinAccountChange'],
  data() {
    return {
      isFetchingAnshin: false,
      form: {
        anshinAccountId: undefined,
        anshinPropertyId: undefined,
        newPropertyCode: '',
        neppanPropertyCode: undefined,
        abbreviation: '',
        name: '',
        nameEn: '',
        nameKana: '',
        mainBrand: '',
        subBrand: '',
        size: 'small',
        tablet: {
          tablet: '',
          mode: '',
          location: '',
        },
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const tmp = JSON.parse(JSON.stringify(nv));
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = tmp[k];
        });
      },
    },
  },
  created() {
    if (this.value.anshinAccountId) {
      this.handleAnshinAccountChange(this.form.anshinAccountId);
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('properties/update', {
          id: this.value.newPropertyCode,
          ...this.form,
        });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    handleAnshinPropertyChange(val) {
      const selected = this.anshinProperties.find((p) => p.accommodationsId === val);
      this.form.name = selected.displayName;
      this.form.maxGuests = selected.maxGuests;
      this.form.address = selected.address;
      this.form.tablet.tablet = selected.smartlock ? 'Yes' : 'No';
    },
    handleAnshinAccountChange(val) {
      this.$emit('anshinAccountChange', val);
    },
    handleNeppanPropertyChange(val) {
      this.form.neppanPropertyCode = val;
    },
  },
};
</script>

<style scoped></style>
