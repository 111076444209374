<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Address') }}
      </h3>
    </div>
    <a-form-item class="column is-6" :label="$t('Postal Code')" name="postalCode">
      <a-input v-model:value.trim="form.postalCode" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Prefecture')" name="prefecture">
      <InputPrefecture
        v-model:value="form.prefecture"
        :size="size"
        country-code="jp"
        value-locale="en"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Address')" name="address">
      <a-input v-model:value="form.address" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Latitude')" name="latitude">
      <a-input-number
        v-model:value="form.latitude"
        :size="size"
        type="number"
        style="width: 100%;"
      />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Longitude')" name="longitude">
      <a-input-number
        v-model:value="form.longitude"
        :size="size"
        type="number"
        style="width: 100%;"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en":{
    "Edit Address": "Edit Address",
    "Postal Code": "Postal Code",
    "Prefecture": "Prefecture",
    "Address": "Address",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Cancel": "Cancel",
    "Save": "Save",
    "Address is required": "Address is required",
    "Postal code is required": "Postal code is required",
    "Prefecture is required": "Prefecture is required",
    "Latitude is required": "Latitude is required",
    "Longitude is required": "Longitude is required"
  },
  "ja":{
    "Edit Address": "住所編集",
    "Postal Code": "施設郵便番号",
    "Prefecture": "都道府県",
    "Address": "施設住所",
    "Latitude": "緯度",
    "Longitude": "経度",
    "Cancel": "取り消し",
    "Save": "保存",
    "Address is required": "施設住所は必須です",
    "Postal code is required": "施設郵便番号は必須です",
    "Prefecture is required": "都道府県は必須です",
    "Latitude is required": "緯度は必須です",
    "Longitude is required": "経度は必須です"
  }
}
</i18n>

<script>
import InputPrefecture from '@/components/InputState';

export default {
  name: 'EditPropertyAddressForm',
  components: {
    InputPrefecture,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        postalCode: '',
        prefecture: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      rules: {
        address: [
          {
            required: true,
            message: this.$t('Address is required'),
            trigger: ['change', 'blur'],
          },
        ],
        postalCode: [
          {
            required: true,
            message: this.$t('Postal code is required'),
            trigger: ['change', 'blur'],
          },
        ],
        prefecture: [
          {
            required: true,
            message: this.$t('Prefecture is required'),
            trigger: ['change', 'blur'],
          },
        ],
        latitude: [
          {
            required: true,
            type: 'number',
            message: this.$t('Latitude is required'),
            trigger: ['change', 'blur'],
          },
        ],
        longitude: [
          {
            required: true,
            type: 'number',
            message: this.$t('Longitude is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const tmp = JSON.parse(JSON.stringify(nv));
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = tmp[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('properties/update', {
          id: this.value.newPropertyCode,
          ...this.form,
        });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
