<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Tax & License') }}
      </h3>
    </div>
    <a-form-item
      class="column is-6"
      :label="$t('Accommodation tax')"
      name="taxLicense.accommodationTax"
    >
      <a-input v-model:value="form.taxLicense.accommodationTax" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Bath tax')" name="taxLicense.bathTax">
      <a-input v-model:value="form.taxLicense.bathTax" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Business License Type')"
      name="taxLicense.businessLicenseType"
    >
      <a-input v-model:value="form.taxLicense.businessLicenseType" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Business License Holder')"
      name="taxLicense.businessLicenseHolder"
    >
      <a-input v-model:value="form.taxLicense.businessLicenseHolder" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Accommodation tax correspondent')"
      name="taxLicense.accommodationTaxCorrespondent"
    >
      <a-input
        v-model:value="form.taxLicense.accommodationTaxCorrespondent"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Accommodation tax correspondent company')"
      name="taxLicense.accommodationTaxCorrespondentCompany"
    >
      <a-input
        v-model:value="form.taxLicense.accommodationTaxCorrespondentCompany"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Bath tax correspondent')"
      name="taxLicense.bathTaxCorrespondent"
    >
      <a-input v-model:value="form.taxLicense.bathTaxCorrespondent" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Bath tax correspondent company')"
      name="taxLicense.bathTaxCorrespondentCompany"
    >
      <a-input
        v-model:value="form.taxLicense.bathTaxCorrespondentCompany"
        :size="size"
        type="text"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit Tax & License": "Edit Tax & License",
"Accommodation tax": "Accommodation tax",
"Bath tax": "Bath tax",
"Business License Type": "Business License Type",
"Business License Holder": "Business License Holder",
"Accommodation tax correspondent": "Accommodation tax correspondent",
"Accommodation tax correspondent company": "Accommodation tax correspondent company",
"Bath tax correspondent": "Bath tax correspondent",
"Bath tax correspondent company": "Bath tax correspondent company",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Edit Tax & License": "税金と免許を編集",
"Accommodation tax": "宿泊税",
"Bath tax": "入湯税",
"Business License Type": "営業許可種別",
"Business License Holder": "営業許可証保有者",
"Accommodation tax correspondent": "宿泊税担当者",
"Accommodation tax correspondent company": "宿泊税担当業者",
"Bath tax correspondent": "入湯税担当者",
"Bath tax correspondent company": "入湯税担当業者",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
export default {
  name: 'EditPropertyTaxLicenseForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        taxLicense: {
          accommodationTax: '',
          bathTax: '',
          businessLicenseType: '',
          businessLicenseHolder: '',
          accommodationTaxCorrespondent: '',
          accommodationTaxCorrespondentCompany: '',
          bathTaxCorrespondent: '',
          bathTaxCorrespondentCompany: '',
        },
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = nv[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('properties/update', {
          id: this.value.newPropertyCode,
          ...this.form,
        });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
