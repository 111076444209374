<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Add Owner') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Partner')" name="partnerId">
      <a-select
        v-model:value="form.partnerId"
        :filter-option="filterOption"
        :size="size"
        show-search
        aria-autocomplete="off"
        autocomplete="off"
      >
        <a-select-option
          v-for="partner in ownerList"
          :key="partner.id"
          :filter="partner.name"
          :data="partner"
          :value="partner.id"
        >
          {{ partner.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add Owner": "Add Owner",
    "Owner": "Owner",
    "Partner": "Partner",
    "Partner is required": "Partner is required",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja": {
    "Add Owner": "オーナー追加",
    "Owner": "オーナー",
    "Partner": "取引先",
    "Partner is required": "取引先は必須です",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
export default {
  name: 'AddOwnerForm',
  props: {
    property: {
      type: Object,
      required: true,
    },
    ownerList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        partnerId: undefined,
      },
      partnerList: [],
      rules: {
        partnerId: [
          {
            required: true,
            type: 'number',
            message: this.$t('Partner is required'),
            trigger: ['change'],
          },
        ],
      },
      size: 'large',
      loading: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        await this.$store.dispatch('properties/addOwner', {
          propertyId: this.property.newPropertyCode,
          ...this.form,
        });

        this.$refs.form.resetFields();
        this.loading = false;
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    filterOption(input, option) {
      return option.filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
