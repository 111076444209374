<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ facility ? $t('Edit Facility') : $t('Add Facility') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Copy From')" name="copyFrom">
      <InputFacility v-model:value="facilityId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Facility Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Category')" name="category">
      <a-input v-model:value="form.category" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Sub Category')" name="subCategory">
      <a-input v-model:value="form.subCategory" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Description')" name="description">
      <a-textarea
        v-model:value="form.description"
        :size="size"
        :auto-size="{ minRows: 2, maxRows: 5 }"
        rows="3"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Partner')" name="partnerId">
      <a-select v-model:value="form.partnerId" :size="size">
        <a-select-option v-for="partner in partnerList" :key="partner.id" :value="partner.id">
          {{ partner.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Add Facility": "Add Facility",
"Edit Facility": "Edit Facility",
"Facility Name": "Facility Name",
"Category": "Category",
"Sub Category": "Sub Category",
"Description": "Description",
"Partner": "Partner",
"Cancel": "Cancel",
"Save": "Save",
"Copy From": "Copy From"
},
"ja": {
"Add Facility": "設備追加",
"Edit Facility": "設備編集",
"Facility Name": "設備名",
"Category": "大区分",
"Sub Category": "小区分",
"Description": "詳細",
"Partner": "取引先",
"Cancel": "取り消し",
"Save": "保存",
"Copy From": "コピー元"
}
}
</i18n>

<script>
import InputFacility from '@/components/InputFacility';

export default {
  name: 'AddFacilityForm',
  components: {
    InputFacility,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    facility: {
      type: Object,
      default: () => null,
    },
    property: {
      type: Object,
      default: () => null,
    },
    partnerList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        name: undefined,
        description: undefined,
        category: undefined,
        subCategory: undefined,
        partnerId: undefined,
      },
      facilityId: undefined,
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    facility: {
      immediate: true,
      handler(nv) {
        if (nv) {
          Object.keys(nv).forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
    facilityId: {
      handler(nv) {
        if (nv) {
          this.$store.commit('SHOW_FULLSCREEN_LOADER');
          this.$store.dispatch('facilities/get', { id: nv })
            .then((resp) => {
              Object.keys(this.form).forEach((k) => {
                this.form[k] = resp[k];
              });
            })
            .finally(() => {
              this.$store.commit('HIDE_FULLSCREEN_LOADER');
            });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        if (this.facility && this.facility.id) {
          await this.$store.dispatch('facilities/update', { ...this.form, id: this.facility.id });
        } else {
          await this.$store.dispatch('facilities/add', {
            propertyId: this.property.newPropertyCode,
            ...this.form,
          });
        }
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
