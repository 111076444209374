<template>
  <a-form ref="form" :model="form.policies" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Policies') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('How to checkin')" name="howToCheckin">
      <a-input v-model:value="form.policies.howToCheckIn" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Checkin time')" name="checkinTime">
      <a-input v-model:value="form.policies.checkInTime" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Checkout time')" name="checkoutTime">
      <a-input v-model:value="form.policies.checkOutTime" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Cut off time')" name="cutOffTime">
      <a-input v-model:value="form.policies.cutOffTime" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('CS tel')" name="csPhone">
      <a-input v-model:value="form.policies.csPhone" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('CS email')" name="csEmail">
      <a-input v-model:value="form.policies.csEmail" :size="size" type="email" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit Policies": "Edit Policies",
"How to checkin": "How to checkin",
"Checkin time": "Checkin time",
"Checkout time": "Checkout time",
"Cut off time": "Cut off time",
"CS tel": "CS tel",
"CS email": "CS email",
"Cancel": "Cancel",
"Save": "Save",
"invalid-email":"Invalid email format.",
},
"ja": {
"Edit Policies": "ポリシー編集",
"How to checkin": "チェックイン方法",
"Checkin time": "チェックイン時刻",
"Checkout time": "チェックアウト時刻",
"Cut off time": "手仕舞い時刻",
"CS tel": "CS tel",
"CS email": "CS email",
"Cancel": "取り消し",
"Save": "保存",
"invalid-email":"無効なメールアドレス形式",
}
}
</i18n>

<script>
export default {
  name: 'EditPropertyPoliciesForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        policies: {
          howToCheckIn: '',
          checkInTime: '',
          checkOutTime: '',
          cutOffTime: '',
          csPhone: '',
          csEmail: '',
        },
      },
      rules: {
        csEmail: [
          {
            type: 'email',
            message: this.$t('invalid-email'),
            trigger: ['change', 'blur', 'input'],
          },
        ],
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const tmp = JSON.parse(JSON.stringify(nv));
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = tmp[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('properties/update', {
          id: this.value.newPropertyCode,
          ...this.form,
        });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
