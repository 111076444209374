<template>
  <Delete
    action-handler="properties/removeOwner"
    :payload-value="{
      propertyId: record.property.newPropertyCode,
      partnerId: record.id,
    }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en": {
"title": "Delete Owner?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?",
"deleted-title": "Owner is deleted",
"deleted-desc": "Owner <strong>{name}</strong> has been removed."
},
"ja": {
"title": "Delete Owner?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?",
"deleted-title": "Owner is deleted",
"deleted-desc": "Owner <strong>{name}</strong> has been removed."
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteOwner',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
